.img {
  transition: 0.3s;
}

.img:hover {
  filter: saturate(100%);
  animation: doubleEffect 0.3s ease-in-out;
}

@keyframes doubleEffect {
  0% {
    filter: hue-rotate(-50deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
