.blob {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Start as a perfect circle */
}

.blob span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Start as a perfect circle */
  transition: 0.5s;
  border: none;
  background: var(--yellow);
}

.blob:hover .span1 {
  animation: animate1 3s linear infinite;
  border-radius: 32% 58% 69% 43% / 48% 32% 59% 55%;
}

.blob:hover .span2 {
  animation: animate2 2s linear infinite;
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
}

.blob:hover .span3 {
  animation: animate1 5s linear infinite;
  border-radius: 31% 45% 74% 35% / 38% 56% 51% 87%;
}

@keyframes animate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
