.App {
  text-align: center;
  background: white;
  height: 100%;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.canvas {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 3;
  pointer-events: none;
  mix-blend-mode: difference;
}

.nav {
  display: flex;
  position: absolute;
  top: 3rem;
  right: 4rem;
}

.nav li {
  padding: 0.5rem;
  margin-left: 3rem;
}

.navBack {
  display: flex;
  position: relative;
  top: 1rem;
  left: 2rem;
}

.navBack li {
  margin-left: 3rem;
}

.canvas-art {
  background-color: var(--gray);
}

.canvas-art div,
.canvas-art svg {
  transition: transform 0.3s ease-in-out;
}

.blob1 {
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  background-color: black;
  width: 200px;
  height: 200px;
}

.blob {
  transition: transform 0.3s ease-out;
}
