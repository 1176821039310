.gradientBorder {
  border-left: 2px solid transparent;
  border-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(107, 107, 107) 33%,
      rgb(107, 107, 107) 67%,
      transparent 100%
    )
    1;
}
