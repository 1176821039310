.contentContainer {
  @apply pb-20 pt-12 text-lg;
}

.contentContainer h1 {
  @apply text-5xl font-bold text-neutral-700 pt-8;
}

.contentContainer h2 {
  @apply text-3xl font-semibold pt-4 pb-2 text-neutral-500;
}

.contentContainer h3 {
  @apply text-lg font-semibold text-neutral-600 py-2;
}

.contentContainer h4 {
  @apply text-3xl font-semibold py-4;
}

.contentContainer h5 {
  @apply text-2xl font-semibold py-4;
}

.contentContainer h6 {
  @apply text-lg font-light pb-4 text-neutral-600;
}

.contentContainer img:first-child {
  @apply py-4;
}

.contentContainer img {
  @apply pb-8 pt-2;
}

.contentContainer li {
  list-style-position: inside;
  list-style-type: decimal;
  @apply py-2;
}

.contentContainer label {
  margin-left: 8px;
}

.contentContainer a {
  color: purple;
}
