.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  margin: auto;
  left: -50px;
  top: -50px;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #000000;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 1.2s linear infinite;
}
.loader::after {
  animation-delay: -0.5s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
